<template>
    <footer>
      <div class="site-content">
        <img src="/img/logo/AntwerpModels_Woordmerk_White_footer.png" class="logo-footer" alt="footer-logo" />
        <div class="link-coordinates">
          <div class="link">
            <div class="email-phone">
              <div class="email">
                <p>email</p>
                <a href="mailto:hello@antwerpmodels.com">hello@antwerpmodels.com</a>
              </div>
              <div class="phone">
                <p>phone</p>
                <a href="tel:+32 3 808 45 39">+32 3 808 45 39</a>
              </div>
            </div>

            <div class="address-follow">
              <div class="address">
                <p>Address</p>
                <a target="_blank" rel="noopener"
                  href="https://www.google.com/maps/place/Meirbrug+1,+2000+Antwerpen,+Belgio/@51.2189158,4.403672,678m/data=!3m1!1e3!4m5!3m4!1s0x47c3f6f9dca1dab5:0xaec8a98f3795d98a!8m2!3d51.2187982!4d4.4047771?hl=it">Meirbrug
                  1, Antwerp</a>
              </div>
              <div class="follow">
                <p>follow us</p>
                <div class="social">
                  <a target="_blank" href="https://www.instagram.com/antwrpmodels/" rel="noopener">Instagram</a>
                </div>
              </div>
            </div>
          </div>
          <div class="coordinates">
            <p>51°13'07.7"N 4°24'17.2"E</p>
          </div>
        </div>
        <div class="container-copyright">
          <p>© {{ new Date().getFullYear() }} ANTWERP MODELS. ALL RIGHTS RESERVED.</p>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>

</style>