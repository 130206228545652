<template>
    <div class="site-content">
      <div class="about-section">
        <div class="about">
          <h1>About am</h1>
          <p class="p-one"><span>Antwerp Models</span> was founded in 2019 and from the beginning our vision has been clear: a model shouldn’t have the perfect measurements but rather be someone who is unique and has a story. Our models pride themselves in being <span>hard-working</span> and will <span>represent your brand the best possible.</span></p>

          <p class="p-two">When you contact Antwerp Models with your project we sit together to understand it fully so that we can present the right models to you that will make <span>your project a success.</span></p>

          <p class="p-four">Is your brand ready to <span>diversify</span> and <span>resonate</span> with a <span>broader audience</span> then contact us for your next project.</p>
          <div class="container-contact-us">
            <a href="mailto:hello@antwerpmodels.com" class="contact-us">Contact us</a>
          </div>
        </div>
        <div class="photo-name">
          <img src="https://antwerpmodelsimg.b-cdn.net/AW21-10.jpeg" alt="" class="lazyload">

          <p>— CONSTANTINE THOMAS (Van Hongo)</p>
        </div>
      </div>

      <div class="instagram">
      </div>
    </div>
</template>

<script>
export default {
    name: 'about'
}
</script>

<style scoped>

</style>