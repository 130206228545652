<template>
<div>
    <nav>
      <span id="brand" class="brand">
        <a href="#"><img src="https://antwerpmodelsimg.b-cdn.net/logo.png" alt=""></a>
      </span>

      <ul id="menu" class="menu">
        <li><a href="mailto:hello@antwerpmodels.com" class="border-link">Contact</a></li>
      </ul>

      <div id="toggle" class="toggle">
        <div class="span" id="one"></div>
        <div class="span" id="two"></div>
        <div class="span" id="three"></div>
      </div>
    </nav>
    <div id="resize">
      <ul id="menu">
        <li><a href="mailto:hello@antwerpmodels.com">Contact</a></li>
      </ul>
    </div>
</div>
</template>

<script>
export default {
    name: 'NavBar'
}
</script>

<style scoped>

</style>
