<template>
    <div class="site-content">
      <div class="container-presentation">
        <img src="https://antwerpmodelsimg.b-cdn.net/presentation-logo.png" alt="" class="logo-big">
        <p class="subtitle">Diversify your brand and resonate with a broader audience.</p>
        <div class="container-btn">
          <a href="mailto:hello@antwerpmodels.com" class="button">Contact Us</a>
        </div>
        <div class="yara"></div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'Hero'
}
</script>

<style scoped>

</style>